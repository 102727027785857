@import '../../css-init/branding/variables.css';

.Loader {
  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99;
  }

  &__with-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__with-container-top {
    margin-top: 50px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__row {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__message {
    font-size: 18px;

    & span {
      vertical-align: middle;
    }

  }

  &__preloader {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0;
    display:inline-block;
    vertical-align: middle;
    position: relative;


    &:before, &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: var(--main);
    }

    &:before {
      animation: 1s loader infinite .5s linear;
    }

    &:after {
      animation: 1s loader infinite linear;
    }

    &--error {
      &:before, &:after {
        background-color: red;
      }
    }
  }
}

@keyframes loader {
  0% {
    transform: scale(0.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
