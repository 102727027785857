@custom-media --mobile (width <= 620px);
@custom-media --tablet (width >= 621px) and (width <= 900px);

@define-mixin hover {
  .no-touch &:hover {
    @mixin-content;
  }
}

@define-mixin button-hover {
  .no-touch &:hover:enabled {
    @mixin-content;
  }
}

@define-mixin wide-click-area $top: 10px, $right: 10px, $bottom: 10px,
  $left: 10px {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -$top;
    bottom: -$bottom;
    left: -$left;
    right: -$right;
    cursor: pointer;
  }
}

@define-mixin grad-over-pink {
  background: linear-gradient(130deg, #905eaf 0%, #5648f8 100%);
}

@define-mixin grad-over-blue {
  background: linear-gradient(130deg, #8f5fae 0%, #22a7e4 100%);
}

@define-mixin grad-blue-pink {
  background: linear-gradient(90deg, #5648f8 0%, #22ccd8 100%);
}

@define-mixin grad-purple {
  background: linear-gradient(90deg, #5648f8 0%, #22ccd8 100%);
}

@define-mixin icon $width, $height, $name, $fill {
  width: $width;
  height: $height;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: svg-load($name, fill=$fill);
}

@define-mixin iconMask $width, $height, $name, $fill {
  width: $width;
  height: $height;
  -webkit-mask: svg-load($name) center/cover;
  background-color: $fill;
}

@define-mixin scroll-shadows $opacity, $bgcolor, $top-shadow-height,
  $bottom-shadow-height {
  background: radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, $opacity),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, $opacity),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: $bgcolor;
  background-size: 100% $top-shadow-height, 100% $bottom-shadow-height;
}

@define-mixin scroll-shadows--hide-up $opacity, $bgcolor, $top-shadow-height,
  $bottom-shadow-height {
  background: linear-gradient($bgcolor 30%, rgba(255, 255, 255, 0)),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, $opacity),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: $bgcolor;
  background-size: 100% $top-shadow-height, 100% $bottom-shadow-height;
}

@define-mixin scroll-shadows--hide-down $opacity, $bgcolor, $top-shadow-height,
  $bottom-shadow-height {
  background: radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, $opacity),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), $bgcolor 70%) 0 100%;
  background-repeat: no-repeat;
  background-color: $bgcolor;
  background-size: 100% $top-shadow-height, 100% $bottom-shadow-height;
}

:root {
  /* app background color */
  --app-background: rgb(245, 247, 248);

  --white: #ffffff;
  /* white with changed opacity */
  --white-09: rgba(255, 255, 255, 0.9);
  --white-07: rgba(255, 255, 255, 0.7);
  --white-06: rgba(255, 255, 255, 0.6);
  --white-03: rgba(255, 255, 255, 0.3);
  --white-025: rgba(255, 255, 255, 0.25);
  --white-02: rgba(255, 255, 255, 0.2);

  /* color for primary content */
  --black: #000000;

  /* color for secondary content and hover */
  --black-light: rgba(0, 0, 0, 0.7);
  /* black with changed opacity */
  --black-08: rgba(0, 0, 0, 0.8);
  --black-05: rgba(0, 0, 0, 0.5);
  --black-04: rgba(0, 0, 0, 0.4);
  --black-02: rgba(0, 0, 0, 0.2);
  --black-01: rgba(0, 0, 0, 0.1);
  --black-007: rgba(0, 0, 0, 0.07);

  /* main color */
  --main: #22ccd8;
  --main-hover: rgba(68, 217, 230, 0.25);
  /* main with changed opacity */
  --main-09: rgba(1, 169, 224, 0.9);
  --main-085: rgba(1, 169, 224, 0.85);
  --main-025: rgba(1, 169, 224, 0.25);
  --main-02: rgba(1, 169, 224, 0.2);
  --main-015: rgba(1, 169, 224, 0.15);
  --main-01: rgba(1, 169, 224, 0.1);
  --main-003: rgba(1, 169, 224, 0.03);

  /* color for background */
  --main-light: rgba(1, 169, 224, 0.07);

  /* color for hover */
  --main-hover: #1ba3ad;
  --trigger-hover: rgba(68, 217, 230, 0.3);

  /* logo color */
  --accent: #22ccd8;

  /* color for word "Editing" */
  --purple: #8f5fae;

  /* color for errors */
  --error-pale: rgb(255, 59, 47);
  --error-hover: rgb(255, 98, 89);
  --error-pale: rgba(255, 59, 47, 0.1);

  /* color for no active text */
  --gray: rgb(152, 166, 173);
  /* gray with changed opacity */
  --gray-07: rgb(152, 166, 173, 0.7);
  --gray-045: rgb(152, 166, 173, 0.45);
  --gray-03: rgb(152, 166, 173, 0.3);

  /* color for notifications */
  --yellow: #f5a623;

  /* EventName component background color */
  --gray-background: #f6f8f8;

  /* colors for audio and video player */
  --errorgray: #dfdfdf;

  /* audio, video, logo */
  --darkgray: rgb(74, 74, 74);
  /* audio, video, logo with changed opacity */
  --darkgray-07: rgba(74, 74, 74, 0.7);
  --darkgray-055: rgba(74, 74, 74, 0.55);
  --darkgray-04: rgba(74, 74, 74, 0.4);
  --darkgray-01: rgba(74, 74, 74, 0.1);

  /* color for app background */
  --gray-light: #f6f6f6;

  /* color at the stats filter */
  --green: rgb(52, 200, 90);
  /* green with changed opacity */
  --green-02: rgba(52, 200, 90, 0.2);
  --green-01: rgba(52, 200, 90, 0.1);

  /* background in the ProfileAside for superuser */
  --superuser-background: linear-gradient(
    140deg,
    rgb(34, 121, 128) 20%,
    rgb(34, 74, 77) 100%
  );

  /* Not editable notification and product badge */
  --notification-background: var(--main-003);
  --notification-border: var(--main-02);

  /* fonts */
  --ptsans: 'PT Sans', sans-serif;
  --sans: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  --gill-sans: 'Gill Sans', Helvetica, sans-serif;

  /* Widget top bar background color */
  --widget-top-bar-bg: linear-gradient(
    120deg,
    rgb(222, 247, 249) 0%,
    rgb(222, 247, 249) 80%
  );

  /* AudioPlayer actions color */
  --phone-action: var(--main);

  /* AudioPlayer gradiental progress bar */
  --phone-action-grad: var(--main);

  /* VideoPlayer actions color */
  --phone-action-secondary: #5648f8;

  /* Options action color */
  --phone-action-thirdly: var(--phone-action-secondary);

  /* Multiple choice background */
  --phone-bg: linear-gradient(
    133.73deg,
    var(--main) 0%,
    var(--phone-action-secondary) 100%
  );

  /* Open-ended (various) choice background */
  --phone-bg-secondary: linear-gradient(
    142.18deg,
    #34c85a 0%,
    var(--main) 100%
  );

  /* Artefact card background */
  --phone-bg-thirdly: linear-gradient(
    133.73deg,
    var(--phone-action-secondary) 0%,
    var(--main) 100%
  );

  /* Close button color */
  --phone-nav: #959595;

  /* Skip button color */
  --phone-nav-secondary: #ebebeb;

  /* Right answer button bg in multiple choice */
  --phone-ok: #34c85a;

  /* Wrong answer button bg in multiple choice */
  --phone-error: #ff3b2f;

  /* Request for tip in open-ended choice */
  --phone-user-focus: #ff3b2f;

  /* Onboarding progressbar color */
  --step: var(--main);

  /* Onboarding log in button color */
  --log-in: var(--main);
  --log-in-hover: rgb(128, 210, 216);
  --log-in-active: #1ba3ad; /* can be a button shadow color */

  /* Material UI colors, move into theme if required */
  --primary-main: #007dff;
  --primary-background: 0, 125, 255;
  --action-background: rgba(0, 0, 0, 0.08);
  --action-disabled-text-opaque: #c4c4c4;
  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --red-100: rgba(228, 70, 86, 0.1);

  --blue-100: rgba(0, 122, 255, 0.1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
}

.Logo {
  width: 85px;
  height: 45px;
  margin: 10px 30px 10px 30px;
  background-color: unset;
  background-size: 85px;
  background-image: svg-load('branding/logo-regular.svg', fill: var(--black));
  background-position: 0px;
}

.QuestShare__heading {
  margin-left: 0;
  padding-left: 11.5px;
}

.ProfileAbout__img {
  width: 145px;
}

.login__img {
  width: calc(100% - 29px);
  height: calc(100% - 70px);
  margin: 35px 29px 35px 0px;
  border-radius: 10px;
}

.login__main-wrapper {
  max-width: 580px;
  padding-right: 100px;

  @media (--mobile) {
    max-width: unset;
    padding-right: 20px;
  }
}
