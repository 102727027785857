@import '../css-init/branding/variables.css';

.ReactModal__Body--open {
  overflow: hidden;
}

#root {
  height: 100%;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
  /* Do not overflow parent by child content of the `.grow` DOM element */
  min-width: 0;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.content-wrap {
  padding: 0 15px;
}

input[type='text']:not(.MuiInputBase-input):not(.LoginAndSignUp input),
input[type='password']:not(.MuiInputBase-input):not(.LoginAndSignUp input){
  padding: 6px 12px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 500px;

  &:focus {
    border-color: #23b7e5;
  }
}

.input__wrapper {
  padding: 5px 10px;
  position: relative;
  border-bottom: 1px solid rgb(238, 238, 238);

  &--normal {
    display: inline-block;
    padding: 0 10px;
    border: 1px solid rgb(238, 238, 238);
    border-radius: 500px;
  }

  & input[type='text'] & input[type='password']:not(.MuiInputBase-input) {
    width: 100%;
    border: 0;
    padding: 0;
    background: transparent;
    font-size: inherit;
  }

  &--enter {
    padding: 5px 15px 10px 5px;
    vertical-align: middle;
    font-size: 14px;

    &:after {
      content: '↵';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      transform: translateY(25%);
      right: 5px;
      opacity: 0.8;
    }
  }
}

.input__number {
  height: 100%;
  margin: 0 0 0 10px !important;
  padding: 5px 0 5px 0 !important;
  border: 0;
  border-right: 1px solid rgb(238, 238, 238) !important;
  border-radius: 0 !important;
  background: transparent;
  font-size: inherit;
  outline: 0;

  &:last-child {
    border: 0 !important;
  }

  &--short {
    width: 30px !important;
  }

  &--long {
    width: 50px !important;
  }
}

button {
  border: 0;
  background: transparent;
  outline: none;

  @mixin hover {
    cursor: pointer;
  }

  &:disabled,
  &:disabled:hover {
    color: rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
}

a {
  color: var(--black);
  text-decoration: none;
}

.font-thin {
  font-weight: 300;
}

h2 {
  font-weight: normal;
}

textarea {
  width: 100%;
  min-height: 100px;
  padding: 5px 10px;
  border-color: rgba(0, 0, 0, 0.1);
  /*border-radius: 5px;*/
}

select {
  padding: 6px 12px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 500px;
  appearance: none;
}

.link {
  cursor: pointer;

  @mixin hover {
    transition: 0.3s;
  }

  &--blue {
    color: var(--main);

    @mixin hover {
      color: var(--main-hover);
    }
  }

  &--red {
    color: var(--error);

    @mixin hover {
      color: var(--error);
    }
  }

  &--light {
    color: var(--white);

    @mixin hover {
      color: var(--white-09);
    }
  }

  &--faded {
    color: var(--black);

    @mixin hover {
      color: var(--main);
    }
  }

  &--underline {
    border-bottom: 1px dotted var(--white);

    @mixin hover {
      border-bottom: 1px solid var(--white-09);
    }
  }

  &--pale {
    color: #505050;

    @mixin hover {
      opacity: 0.7;
    }
  }

  &--black {
    color: var(--black);
    border-bottom: 1px dashed var(--black);

    @mixin hover {
      opacity: 0.7;
    }
  }
}

.rubles {
  &:after {
    content: ' ₽';
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.button--fetching {
  animation: 1s blinking infinite;
}

.section {
  /* TODO: universal padding */
}

.__react_component_tooltip {
  z-index: 9999 !important;
  font-weight: bold !important;
}
