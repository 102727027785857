@import '../css-init/branding/variables.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-size: 18px;*/
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  font-family: var(--sans);
  /*overflow: hidden;*/

  #supportTrigger {
    display: none;
  }
}

select,
textarea,
input,
button,
code {
  font-size: 16px;
  font-family: var(--sans);
  outline: none; /*TODO: replace with another accessability feature */
}

.touch {
  & label {
    cursor: pointer;
  }
  & label > * {
    pointer-events: none;
  }
}

body.is-dragging {
  cursor: grabbing;
}
